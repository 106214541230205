import axios from "axios";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.baseURL = "https://ws.growapp-studio.keenetic.pro/api";
    // config.baseURL = "https://192.168.1.13:8585/api";
    // config.baseURL = "https://192.168.1.85:8585/api";
    // config.baseURL = `${process.env.VUE_APP_API_URL}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
