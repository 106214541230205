import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36d97921"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gwa-app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Home = _resolveComponent("Home")!
  const _component_Products = _resolveComponent("Products")!
  const _component_Contacts = _resolveComponent("Contacts")!
  const _component_Order = _resolveComponent("Order")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Home, {
      onOpenContactModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true))
    }),
    _createVNode(_component_Products),
    _createVNode(_component_Contacts, {
      onOpenContactModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = true))
    }),
    _withDirectives(_createVNode(_component_Order, {
      onCloseContactModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModal = false))
    }, null, 512), [
      [_vShow, _ctx.showModal]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "modal-background",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModal = false))
    }, null, 512), [
      [_vShow, _ctx.showModal]
    ])
  ]))
}