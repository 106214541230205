
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Home from "@/components/Home.vue"; // @ is an alias to /src
import Products from "@/components/Products.vue"; // @ is an alias to /src
import Contacts from "@/components/Contacts.vue"; // @ is an alias to /src
import Order from "@/components/Order.vue"; // @ is an alias to /src

@Options({
  components: {
    Home,
    Products,
    Contacts,
    Order,
  },
})
export default class Main extends Vue {
  showModal = false;

  @Watch("showModal")
  onShowModalChanged(value: boolean): void {
    document.documentElement.style.overflow = value ? "hidden" : "auto";
  }
}
