
import { Vue } from "vue-class-component";

export default class Order extends Vue {
  order = {
    website: false,
    app: false,
    ws: false,
    integration: false,
  };

  username = "";
  useremail = "";
  usercomment = "";

  errors = {
    username: false,
    useremail: false,
  };

  emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  closeOrder(): void {
    this.$emit("closeContactModal");
  }

  validateOrder(): boolean {
    this.errors.username = this.username.trim() === "";
    this.errors.useremail =
      this.useremail.trim() === "" || !this.emailRegExp.test(this.useremail);

    return this.errors.username === false && this.errors.useremail === false;
  }

  clearOrder(): void {
    this.order = {
      website: false,
      app: false,
      ws: false,
      integration: false,
    };
    this.username = "";
    this.useremail = "";
    this.usercomment = "";
  }

  sendOrder(): void {
    if (!this.validateOrder()) {
      return;
    } else {
      this.axios
        .post("/order", {
          username: this.username,
          useremail: this.useremail,
          usercomment: this.usercomment,
          order: JSON.stringify(this.order),
        })
        .catch((err) => {
          console.error(err);
        });
      this.clearOrder();
      this.$emit("closeContactModal");
    }
  }
}
